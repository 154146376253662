@import "../../../../scss/colours";
@import "../../../../scss/elevation";

.root {
    background-color: fade-out($petrol, 0.1);
    border: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: scale-color($color: white, $lightness: -10%);
    margin-top: 8px;
    outline: none;
    padding: 8px;
    right: 0;
    text-align: left;
    width: 125%;

    position: absolute;

    @include elevation(16);

    .closeButton {
        color: $mist;
        cursor: pointer;
        position: absolute;
        top: 3px;
        right: 5px;
    }
}