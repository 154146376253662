@import '~@3tc/styleguide/src/resources/variables';

.ol-overlaycontainer-stopevent {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    margin-top: 8px;
    button {
        width: 40px;
        height: 35px;
        font-size: 20px;
        font-weight: bolder;
        margin-right: 8px;
        background-color: $spark;
        color: $dark-petrol;
        border: 3px solid $mist;
        border-radius: 3px;
        outline: none;
        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
        cursor: pointer;
    }

    div {
        display: flex;
    }

    button:hover {
        background-color: $dark-petrol;
        color: $spark;

        svg {
            fill: $spark;
        }
    }

    button:focus {
        background-color: $dark-petrol;
        color: $spark;
        border: 3px dashed $spark;
 
        svg {
            fill: $spark;
        }
    }

    svg {
        width: 16px;
    }
}

.home-map .ol-overlaycontainer-stopevent {
    position: fixed;
    bottom: 25vh;
    left: 64%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    div {
        flex-direction: column;
    }

    button {
        margin-bottom: 4px;
    }
}

@media (min-width: 900px) {
    .home-map .ol-overlaycontainer-stopevent {
        position: absolute;
        bottom: 8px;
        left: 50%;
        flex-direction: row;

        div {
            flex-direction: row;
        }

        button {
            margin-bottom: 0;
        }
    }

}

.tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.distance-marker-overlay {
    color: white;
    background-color: fade-out(black, $amount: 0.2);
    padding: 4px;
    position: relative;
}

.ol-mouse-position {
    position: absolute;
    bottom: 51px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding: 4px 8px;
    margin-bottom: 4px;
    background: $dark-petrol;
    opacity: 0.75;
    border-radius: 4px;
    width: fit-content;
    font-size: small;
}

.ol-overlaycontainer-stopevent .ol-mouse-position_hidden {
    display: none;
}