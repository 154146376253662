@import "../../../scss/colours";

$sea-blue: #aadeef;

@mixin fullPage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.root {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .loadingOverlay {
        @include fullPage;
        align-items: center;
        background-color: scale-color($color: $petrol, $alpha: -40%);
        display: inline-flex;
        justify-content: center;
        z-index: 100;
    }

    .map {
        background-color: $sea-blue;
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        &.cadcorp {
            background-color: white;
        }
    }

    .overlayContainer {
        display: none;

        .marker {
            background-color: transparent;
            display: inline-flex;
        }
    }
}