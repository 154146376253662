@import "@3tc/styleguide/src/resources/variables.scss";
@import "~@3tc/styleguide/src/partials/btn";
@import "../../scss/colours";

.login__container {
    background-color: $dark-petrol;
    color: white;
    width: 100vw;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        width: 300px;
    }
    img {
        width: 300px;
    }

    h1 {
        font-weight: 700;

    }
}