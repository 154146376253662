@import "../../../../../scss/colours";

.listItemOrder {
    color: $spark;
    font-weight: bold;
    margin-right: 12px;
}

.listItem {
    transform: translate3d(0, 0, 0); // this is to fix a bug with the default dragImage used in chrome
}

.listItemDraggedOver {
    border-top: 2px dashed $spark;
}

.listItemPrimary {
    display: flex;
    justify-content: space-between;

    >span {
        font-weight: bold;
    }

    >div {
        display: flex;
    }
}

.listItemSecondary {
    color: white;
}