@import "./colours";

.responsiveTable {
    border-collapse: collapse;
    width: 100%;

    thead {
        color: $mist;
        display: none;
    }

    th {
        text-align: left;
    }

    td {
        word-break: break-word;
    }

    tr {
        border-bottom: 1px solid scale-color($color: $petrol, $lightness: -43%);
        display: flex;
        flex-direction: column;
        padding: 8px 0 16px 0;
        width: 100%;

        &:first-child {
            border-top: 1px solid scale-color($color: $petrol, $lightness: -43%);
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 8px;
        }
    
        td + td, 
        th + th, 
        th + td {
            margin-top: 8px;
        }

        &:hover {
            background-color: scale-color($color: $petrol, $lightness: 15%);
        }
        
        &:focus, 
        &:active {
            background-color: scale-color($color: $petrol, $lightness: 30%);
        }
    }
}

@media (min-width: 768px) {
    .responsiveTable {
        thead {
            display: table-header-group;
        }

        td, th {
            padding: 16px 0;
        }
        th {
            border-bottom: 1px solid $mist;
            display: table-cell;
            text-align: left;
        }

        tr {
            border-bottom: 1px solid $mist;
            display: table-row;
            &:first-child {
                border-top: none;
                padding: 0;
            }
            &:last-child {
                border-bottom: none;
                padding: 0;
            }
            &:hover {
                background-color: scale-color($color: $petrol, $lightness: 15%);
            }
        }
    }
}