@import "../../../../scss/colours";

.root {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-top: 4px;

    .title {
        color: $spark;
    }

    .subTitle {
        color: scale-color($color: $spark, $lightness: -35%);
    }
}