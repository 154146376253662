@import '../../scss/colours';

.root {
    align-items: center;
    display: flex;
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    
    .iconContainer {
        display: flex;
        margin-right: 16px;

        .icon {
            height: 24px;
            max-height: 24px;
            max-width: 24px;
            width: 24px;
        }
    }

    .label {

    }
}
