@import "~@3tc/styleguide/src/resources/variables";
@import "../../../../scss/colours";

.root {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.5rem;

    .totalCount {
        background-color: $spark;
        border: none;
        border-radius: 3px;
        color: $petrol;
        line-height: 1rem;
        padding: 0;
        padding: 3px 5px;
    }

}