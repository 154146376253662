@import "../../../../scss/colours";

.root {
    color: white;
    padding: 0 5%;

    .waterSourceTitle {
        padding: 16px;
        color: $spark;
    }

    .loadingMessage {
        margin-top: 1em;
        font-size: 1em;
        color: $spark;
    }

    .tabBar {
        padding: 4px;
    }

    .tab {
        color: white;
        min-width: unset;
        &:focus {
            color: $spark
        }
    }

    .tabContainer {
        height: 100%;
        overflow: hidden;
        position: relative;
    }
}

.map {
    margin-bottom: 1rem;
    width: 100%;
    min-height: 200px;
    height: auto;
}

.panels-container {
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.panel-left-section,
.panel-right-section {
    width: 48%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.panel-left-section {
    height: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-left: 0;
    border-right: 1px solid #fff;
    h4 {
        margin-top: 0;
    }

    &.waterSource {
        padding: 0;
    }
}

.panel-left-section::-webkit-scrollbar {
    width: 0 !important;
    background: transparent;
}

.print-list {
    display: none;
}

@media (min-width: 1100px) {
    .list {
        width: 50%;
        border-right: 1px solid $mist;
    }
}

@media print {

    * {
        background-color: white;
        color: $dark-petrol;
        overflow: visible !important;
        scrollbar-width: none;
    
        &::-webkit-scrollbar {
            display: none; 
        }
    }

    button, nav {
        display: none !important;
    }

    .map {
        margin: auto;
        width: 750px;
        height: 500px;

        overflow: hidden !important;
    }

    .selected-child-container {
        width: 75%;
        margin: auto;
    }

    .list {
        display: none;
    }

    .print-list {
        width: 75%;
        margin: auto;
        display: block;

        h4 {
            margin-top: 16px;
        }
    }
}
