@import "../../../../scss/colours";

.repairControlContainer {
    width: 100%;

    .repairControl {
        padding: 0 20px 20px 20px;

        .actionsContainer {
            margin: 10px 0;
            button {
                margin-right: 5px;
            }
        }
    
        .repairData {
            @include colour("white");
        }
    }
}