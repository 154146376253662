.root {
    display: flex;
    flex-direction: column;

    margin-top: 6px;

    .postCode {
        margin-top: 2px;
    }
}

@media screen and (orientation: landscape) {
        .root {
        flex-direction: column;

        margin-left: 24px;
        margin-top: 0;

        .postCode {
            margin-left: unset;
            margin-top: 6px;
        }
    }
}