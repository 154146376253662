@import "../../../../scss/colours";

.waterSourceControl {
    width: 100%;
    padding: 20px;

    @include colour("white");

    .locationContainer {
        display: flex;

        &--edit {
            cursor: pointer;
            margin-top: 0 !important;
        }
    }

    .separation-line {
        color: $spark;
        border-bottom: solid 1px $spark  !important;
        width: 100%;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .addressContainer {
        padding: 12px !important;
        padding-top: 0px !important;
        margin-top: 16px !important;
        margin-bottom: 8px !important;
        border: 1px solid white;

        .addressLabel {
            position: relative;
            top: -10px;
            background-color: #16222c;
            margin-bottom: -10px !important;
        }
    }

    .mains-size-fields {
        display: inline-flex;

        .labelledField {
            display: inline-block;
        }

        #water-source-mains-size {
            margin-top: 5px;
            margin-right: 5px;
        }

        #water-source-mains-size-units {
            margin-top: 5px;
        }
    }

    .next-inspection-date {
        display: inline-block;
    }

    .wm-actions {
        button {
            margin: 0 5px 10px 0;
        }
    }

    .action-button--hazards {
        display: block;
        margin-bottom: 0.5rem;
    }

    .switchContainer {
        display: inline-block;
        cursor: pointer;
    }
}

@media (min-width: 700px) {
    .waterSourceControl--fullPage {
        display: flex;
        justify-content: space-between;

        &>div {
            width: 100%;
        }

        .waterSourceControl__meta_one {
            margin-right: 16px;
        }
    }
}