@import '../../../../scss/colours';

.inspectionControlContainer {
    width: 100%;
    .backButton {
        @include colour("white");
        display: flex;
        margin-top: 5px;
        cursor: pointer;
        font-size: 0.8rem;
    
        &-hover {
            @include colour("spark");
        }
    
        .backButtonText {
            align-self: center;
            padding-right: 5px;
        }
    }

    .action-buttons-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }
   
    .inspectionControl {
        @include colour("white");
        width: 100%;
        padding: 0 20px 20px 20px;
    }
}

.inspectors-container {
    margin: 1.5rem 0;

    .inspectors-list {

    }

    .inspectors-button-container {
        display: flex;
        margin-bottom: 0.5rem;

        button {
            flex-grow: 1;
        }
    }
}

.action-button--inspectors {
    display: block;
}