.hidden_waterSource_inputReference_container {
    .action-button {
        width: 50px;
        margin: 0;
        text-align: center;
        .bp3-icon {
            margin-left: auto;
            margin-right: auto;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.dateInput {
    &.createDefect-dateInput {
        flex-grow: 1;
        max-width: unset;
        width: unset;
    }
}
