@import "../../scss/colours";

.root {
    padding: 24px;

    .container {
        margin-top: 16px;
        display: flex;
            
        >div {
            min-width: 700px;
        }

        .lookupItemsContainer {
            display: flex;

            .lookupItems {
                flex-grow: 1;
                >div {
                    >span {
                        &:focus-within {
                            border-color: #ffcf21;
                        }
                    }
                }
                select {
                    min-height: 400px;
                    max-height: 400px;
                }
            }

            .lookupOrderButtons {
                display: flex;
                flex-direction: column;
                justify-content: center;

                >button:nth-of-type(1) {
                    margin-bottom: 4px;
                }
            }
        }

        .selectedLookupItem {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .actionButtonContainer {
                align-self: flex-end;
            }
        }
    }

    .typeToSearch {
        width: 100%;
        min-width: 240px;
        max-width: 600px;
        margin-bottom: 0;
    }

    .typography {
        color: $mist;
    }
}
