.root {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 6px;
}

@media screen and (orientation: landscape) {
        .root {
        flex-direction: column;
        align-items: flex-start;
        justify-content: unset;

        margin-left: 24px;
        margin-top: 0;

        .date {
            margin-top: 6px;
        }
    }
}
