@import "../../../../scss/colours";

.root {
    align-items: center;

    .label {

    }

    .container {
        display: inline-block;
        height: 18px;
        width: 18px;
        position: relative;

        .input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border: none;
            margin: 0;
            outline: 0;
            padding: 0;
            opacity: 0;
            z-index: 1;

            &:checked + .check:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }

            &:not(:checked) + .check:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }

            &:focus {
                border-color: $spark;
                border-style: dashed;
            }
        }

        .check {
            cursor: pointer;
            font-size: 0.8rem;
            height: 100%;
            width: 100%;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 18px;
                width: 18px;
                background: $mist;
                border: 2px solid $petrol;
            }

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: 14px;
                height: 14px;
                background: $spark;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }
}
