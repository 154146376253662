@import "../../../../../scss/colours";
@import "../../../../../scss/elevation";

$associated-vertical-margin: 16px;
$vertical-margin: $associated-vertical-margin * 2;

.root {
    display: flex;
    flex-direction: row;

    .inputGroup {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
            margin-right: 8px;
        }

        .radio {
            height: 0.1px;
            width: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .label {
            align-items: center;
            border: none;
            border-radius: 16px;
            cursor: pointer;
            display: inline-flex;
            height: 32px;
            font-weight: bold;
            justify-content: center;
            max-height: 32px;
            min-height: 32px;
            outline: none;
            padding: 0 12px;
            user-select: none;

            background-color: $petrol;
            color: scale-color($color: white, $alpha: -75%);

            &:focus,
            &:hover {
                    background-color: scale-color($color: $petrol, $lightness: 10%);;
            }
            &:active {
                background-color: scale-color($color: $petrol, $lightness: 15%);;
                @include elevation(8);
            }
        }
        
        .radio[type="radio"]:checked + .label {
            background-color: $spark;
            color: $petrol;
            pointer-events: none;

            &:focus,
            &:hover {
                background-color: scale-color($color: $spark, $lightness: 20%);
            }
            &:active {
                background-color: scale-color($color: $spark, $lightness: 40%);
                @include elevation(8);
            }
        }

        .radio[type="radio"]:disabled + .label {
            background-color: scale-color($color: white, $alpha: -90%);
            pointer-events: none;
        }
    }
}