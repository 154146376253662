.root {
    padding: 24px;

    .container {
        margin-top: 16px;

        .list {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }
}

.topPageControlContainer {
    margin-bottom: 20px;
}
