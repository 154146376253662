@import "../../scss/colours";

$drawer-width: 500px;

.drawer {
    width: $drawer-width;

    .paper {
        background-color: fade-out($dark-petrol, 0.1);
        height: calc(100vh - 64px); 
        top: 64px;
        width: $drawer-width;
        z-index: 1;
    }
}
