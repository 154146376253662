@import '~@3tc/styleguide/src/resources/variables';

.tags-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    margin-right: 5px;
}

.tag-input {
    display: inline-block;
    margin: 0 0 5px 0;
}

#add-tag-button {
    margin-left: 0;
}