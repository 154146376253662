@import '~@3tc/styleguide/src/resources/variables';

.wm-map-page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    background-color: #aadeef;

    &.cadcorp {
        background-color: white;
    }
}

@media print {
    .wm-map-page {
        height: 100vh;
        width: 100vw;
    }
}

// Custom styling for action buttons, required to overwrite the shared-components styles
.wm-map-page .ol-overlaycontainer-stopevent {
    padding: 8px;
    bottom: 0;
    background: $dark-petrol;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    button {
        background-color: $petrol;
        color: $spark;
        margin-right: 4px;
        margin-left: 4px;
        border: 2px solid #a7a8aa;
        border-radius: 2px;
        -webkit-transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
        cursor: pointer;
        svg {
            fill: $spark;
        }
        &:hover {
            background-color: $dark-petrol;
            svg {
                fill: $spark;
            }
        }
        &:focus {
            background-color: $dark-petrol;
            border: 2px solid #a7a8aa;
        }
        .bp3-icon {
            vertical-align: middle !important;
        }
    }

    .ol-rotate-reset {
        padding-bottom: 4px;
        &:focus {
            border: 2px solid #a7a8aa;
            background-color: $petrol;
        }
    }

    .zoom-controls-in,
    .zoom-controls-out {
        &:focus {
            border: 2px solid #a7a8aa;
            background-color: $petrol;
        }
    }
}

.active-action {
    background-color: $dark-petrol !important;
    border: 2px dashed $spark !important;
}