@import "../../scss/colours";

.idContainer {
    margin-bottom: 0
}

.title {
    color: $spark;
    
    &:hover {
        color: white;
    }
}