@import "../../../../scss/colours";

.noOptionsMessage {
    display: inline-block;
    font-size: 1rem;
    margin: 0 0 8px 8px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    width: 100%;

    .footer {
        display: flex;
        flex-direction: row;
        margin-top: 8px;

        .clearButton {
            color: $spark;
            cursor: pointer;
            font-size: 0.65rem;
            margin-left: auto;
            text-align: right;
        
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
