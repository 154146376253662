@import '~@3tc/styleguide/src/resources/variables';

.searchPage {
    padding: 24px;
    label {
        color: $spark;
        text-transform: capitalize;
    }
    hr {
        margin: 0 auto 2em auto;
        border: 1px solid $spark;
    }
}
