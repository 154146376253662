@import '~@3tc/styleguide/src/resources/variables';

#file-modal--watersource {
    label::after {
        top: 6px;
        left: 7px;
    }
}

#file-modal--slider {
    position: absolute;
    top: 100px;
    height: auto;
    width: 100%;
    label::after {
        top: 6px;
        left: 7px;
    }
}

.file-dialog {
    .bp3-dialog {
        width: unset;
    }
}
