@import "../../../../scss/colours";

.root {
    &:hover {
        background-color: fade-out($color: black, $amount: 0.8);
    }
    &:active {
        background-color: fade-out($color: black, $amount: 0.7);
    }

    .link {
        align-items: center;
        background-color: transparent;
        border: none;
        color: scale-color($color: white, $lightness: -10%);
        display: flex;
        flex-direction: row;
        margin: 0 8px 0 12px;
        outline: none;
        padding: 8px 0;
        
        &:active {
            color: $spark;
        }

        .icon {
            margin-right: 12px
        }
    
        .itemText {
            display: flex;
            flex-direction: column;
            
            .id {
                //
            }
            .address {
                font-size: calc(9 * 0.0625rem)
            }
            .location {
                font-size: calc(9 * 0.0625rem)
            }
        }
    }

    &.inline {
        .link {
            text-decoration: none;
            .itemText {
                & div+div {
                    margin-left: 0;
                }
            }
        }
    }
}
