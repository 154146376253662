.root {
    padding: 1rem;
    color: white;

    .actionButtonsContainer {
        display: flex;
        justify-content: flex-end;
    }

    .waterSourceTitle {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .waterSourceActionsContainer {
        display: flex;
        flex-direction: column;
    }

    .waterSourcesList {
        margin-top: 8px;
    }
}
