@import "../../../../scss/colours";
@import "../../../../scss/elevation";

.root {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;

    background-color: fade-out($petrol, 0.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: scale-color($color: white, $lightness: -10%);
    padding: 0;
    width: 125%;
    z-index: 150;

    @include elevation(16);

    &.inline {
        width: 100%;
    }

    .results {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .pagination {
        margin-bottom: 16px;
    
        &>div {
            display: flex;
            flex-direction: column;

            &>div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: unset;
                &>p {
                    font-size: 11px;
                }
            }
        }

        button {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 32px;
            padding: 0;
            width: 32px;
        }

        button:nth-child(2) {
            &>svg {
                margin-left: -4px;
            }
        }
    
        svg {
            width: 18px;
            height: 18px;
        }
    }
}

