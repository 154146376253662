@import "../../../scss/colours";

.allInputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 1440px;
    overflow: auto;
    height: auto;
    max-height: 500px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    max-height: 200px;
    small {
        margin-top: 0.5rem;
        color: $spark;
    }
    select {
        max-width: 200px;
        overflow: auto;
        scrollbar-width: none;
        margin-bottom: 0;
    }
}
    
.viewOptions {
    margin: 16px 0;
}
