@import '../../../scss/forms';


.wm-dialog-body {
    .bp3-tabs {
        .bp3-tab {
            width: 50%;
            text-align: center;
    
            &-indicator-wrapper {
                width: 50% !important;
            }
        }
    }
    
    .locationContainer {
        display: flex;
        max-width: 340px;
        max-height: 50px;
    
        input {
            margin-right: 1rem;
        }
    }
}