@import "../../../../scss/colours";

.root {
    border: 1px solid $petrol;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 72px;
    padding-bottom: 8px;
    padding-right: 8px;

    &.fail {
        border: 1px solid $fuse;
    }
    
    .row {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &.header {
            justify-content: unset;
        }

        &.waterSource {
            align-items: center;
            padding: 4px 8px 0 32px;
        }

        &.notification {
            align-items: center;
            flex-grow: 1;
            justify-content: space-between;
            padding: 4px 8px 0 32px;
        }

        .actions {
            display: flex;
            flex-direction: row;
            padding-left: 16px;
            white-space: nowrap;
            
            .action {
                margin-right: 48px;
                width: 168px;
            }
        }
        .internalTasks {
            margin-right: 48px;
            overflow: hidden;
        } 

        .info {
            font-style: italic;
            justify-content: flex-end;
            margin-left: auto;
            padding-right: 8px;
            white-space: nowrap;
        }
    }

    .inspection {
        align-items: center;
        background-color: $petrol;
        border: 1px solid $petrol;
        color: scale-color($color: white, $lightness: -10%);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        min-width: 256px;
        outline: none; 
        padding: 4px;
        padding-right: 8px;
    
        .id, .outcome {
            text-transform: capitalize;
        }

        .outcome {
            font-weight: bold;
        }
    
        &.fail {
            background-color: $fuse;
            border: 1px solid $fuse;
        }
    }
}
