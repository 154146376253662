@import "../../../../scss/colours";

.column {
    flex-direction: column;
}
.row {
    flex-direction: row;
}

.root {
    display: flex;

    .divider {
        &.inset {
            border: 1px solid scale-color($color: $petrol, $lightness: -15%);
            margin-left: 16px;
            margin-right: 16px;
        }
        &.full {
            border: 1px solid scale-color($color: $petrol, $lightness: -15%);
            margin-left: 0;
            margin-right: 0;
        }
        &.none {
            display: none;
        }
    }
    
    .children {
        display: flex;
        padding: 0 16px 0 16px;
    }
}