@import '../../scss/colours';

.root {
    position: fixed;
    bottom: 16px;
    left: 16px;
    text-align: center;

    .openKeyButton {
        align-items: center;
        background-color: $petrol;
        border: 2px solid #a7a8aa;
        border-radius: 2px;
        color: $spark;
        cursor: pointer;
        display: flex;
        font-size: 20px;
        height: 35px;
        justify-content: center;
        width: 40px;
        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
        -webkit-transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

        &:hover {
            background-color: $dark-petrol;
        }

        &:focus {
            border: 2px dashed $spark;
        }
    }
}

@media print {
    .root {
        transform: translateY(0);
    }
}

.keyContainer {
    background-color: $dark-petrol;
    display: flex;
    padding: 16px;

    .key {
        list-style: none;
        padding: 0;
        margin: 0;
        // position: relative;
        // padding: 10px;
    }
}
