@import "../../../../scss/colours";
@import "../../../../scss/elevation";

.fab {
    border: none;
    border-radius: 50%;
    height: 56px;
    margin-top: 16px;
    outline: none;
    pointer-events: visible;
    width: 56px;
    z-index: 6;
    @include elevation(6);

    &:hover, &:focus {
        @include elevation(8);
        outline: none;
    }

    &:active {
        @include elevation(12);
        outline: none;
    }

    &.primary {
        background-color: $spark;
        &:hover, &:focus {
            background-color: scale-color($color: $spark, $lightness: 25%);
        }
        &:active {
            background-color: scale-color($color: $spark, $lightness: 75%);
        }
    }

    &.secondary {
        background-color: $mist;
        &:hover, &:focus {
            background-color: scale-color($color: $mist, $lightness: 25%);
        }
        &:active {
            background-color: scale-color($color: $mist, $lightness: 75%);
        }
    }
}
