$inverted: "invert";

@mixin elevation ($height, $invert: "") {
    $voffset: if($invert == "invert", -1, 1);
    @if $height==0 {
        box-shadow: none
    }
    @else if $height==1 {
        box-shadow: 0 ($voffset * 1px) 1px 0 rgba(0, 0, 0, .14), 0 ($voffset * 2px) 1px -1px rgba(0, 0, 0, .12), 0 ($voffset * 1px) 3px 0 rgba(0, 0, 0, .20)
    }
    @else if $height==2 {
        box-shadow: 0 ($voffset * 2px) 2px 0 rgba(0, 0, 0, .14), 0 ($voffset * 3px) 1px -2px rgba(0, 0, 0, .12), 0 ($voffset * 1px) 5px 0 rgba(0, 0, 0, .20)
    }
    @else if $height==3 {
        box-shadow: 0 ($voffset * 3px) 4px 0 rgba(0, 0, 0, .14), 0 ($voffset * 3px) 3px -2px rgba(0, 0, 0, .12), 0 ($voffset * 1px) 8px 0 rgba(0, 0, 0, .20)
    }
    @else if $height==4 {
        box-shadow: 0 ($voffset * 4px) 5px 0 rgba(0, 0, 0, .14), 0 ($voffset * 1px) 10px 0 rgba(0, 0, 0, .12), 0 ($voffset * 2px) 4px -1px rgba(0, 0, 0, .20)
    }
    @else if $height==6 {
        box-shadow: 0 ($voffset * 6px) 10px 0 rgba(0, 0, 0, .14), 0 ($voffset * 1px) 18px 0 rgba(0, 0, 0, .12), 0 ($voffset * 3px) 5px -1px rgba(0, 0, 0, .20)
    }
    @else if $height==8 {
        box-shadow: 0 ($voffset * 8px) 10px 1px rgba(0, 0, 0, .14), 0 ($voffset * 3px) 14px 2px rgba(0, 0, 0, .12), 0 ($voffset * 5px) 5px -3px rgba(0, 0, 0, .20)
    }
    @else if $height==9 {
        box-shadow: 0 ($voffset * 9px) 12px 1px rgba(0, 0, 0, .14), 0 ($voffset * 3px) 16px 2px rgba(0, 0, 0, .12), 0 ($voffset * 5px) 6px -3px rgba(0, 0, 0, .20)
    }
    @else if $height==12 {
        box-shadow: 0 ($voffset * 12px) 17px 2px rgba(0, 0, 0, .14), 0 ($voffset * 5px) 22px 4px rgba(0, 0, 0, .12), 0 ($voffset * 7px) 8px -4px rgba(0, 0, 0, .20)
    }
    @else if $height==16 {
        box-shadow: 0 ($voffset * 16px) 24px 2px rgba(0, 0, 0, .14), 0 ($voffset * 6px) 30px 5px rgba(0, 0, 0, .12), 0 ($voffset * 8px) 10px -5px rgba(0, 0, 0, .20)
    }
    @else if $height==24 {
        box-shadow: 0 ($voffset * 24px) 38px 3px rgba(0, 0, 0, .14), 0 ($voffset * 9px) 46px 8px rgba(0, 0, 0, .12), 0 ($voffset * 11px) 15px -7px rgba(0, 0, 0, .20)
    }
    @else {
        @error "Invalid elevation " + $height + "; must be one of: 0, 1, 2, 3, 4, 6, 8, 9, 12, 16, 24.";
    }
}