@import "../../../../scss/colours";

.root {
    background-color: $petrol;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;

    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-evenly;
    left: 8px;
    padding: 8px 16px;
    position: absolute;
    top: calc(100%);
    width: 190px;
    z-index: 3;

    &.inline {
        left: 0;
        top: calc(100% + 4px);
        width: 100%
    }
}
