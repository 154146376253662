@import "../../../../scss/colours";

.QuickSearchInput_root {
    &.bp3-input-group {
        .bp3-icon {
            @extend .mist-75;
            top: 8px;
        }
    }
    
    .bp3-input[type="search"] {
        border: 3px dashed transparent;
        border-radius: 0;
        min-height: 46px;

        &:focus {
            border-color: $spark;
            box-shadow: none;        
            outline: none;
        }
    }

    &.QuickSearchInput_notInline {
        color: $dark-petrol;
    
        &.bp3-input-group {
            .bp3-icon {
                color: $petrol !important;
                top: 0px;
                left: 2px;
            }
        }
    
        .bp3-input[type="search"] {
            background-color: white !important;
            border-radius: 15px;
            min-height: unset;
    
            &::placeholder {
                color: $mist;
            }
        }
    }

    &.QuickSearchInput_inline_invalid {
        border-bottom: 2px solid red !important;
    }

    &.QuickSearchInput_invalid {
        color: $fuse;
    }
}
