@import "../../../../scss/colours";

.labelledField {
    align-self: center;
    align-items: baseline;
    color: white;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    &.noMargin {
        margin-bottom: 0;
    }
}
