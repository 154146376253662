@import "../../scss/colours";
@import "../../scss/elevation";

.card {
    background-color: $petrol;
    border-radius: 4px;
    margin-top: 8px;
    padding: 16px 0 8px 0;

    display: flex;
    flex-direction: column;

    @include elevation(1);
}