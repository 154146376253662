@import "../../../scss/colours";

.bp3-spinner {
    &.mobile-map {
        .bp3-spinner-animation {
            .bp3-spinner-track {
                stroke: scale-color($color: $petrol, $alpha: -80%) !important;
            }
            .bp3-spinner-head {
                stroke: scale-color($color: $petrol, $alpha: -20%) !important;
            }
        }
    }
}
