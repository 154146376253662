@import '~@3tc/styleguide/src/resources/variables';

.all-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1440px;
    overflow: auto;
    height: auto;
    max-height: 500px;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    max-height: 200px;
    small {
        margin-top: 0.5rem;
        color: $spark;
    }
    select {
        max-width: 200px;
        overflow: auto;
        scrollbar-width: none;
        margin-bottom: 0;
        option {
            text-transform: capitalize;
        }
    }
}
    
.viewOptions {
    margin: 16px 0;

}

.action-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid $spark;
    border-bottom: 1px solid $spark;
    margin: 1.5rem auto;
    padding: 1rem 0;
}

.labelledFieldContainerOverride {
    flex-direction: column;
    max-width: 200px;
}

.labelledFieldLabelOverride {
    align-self: flex-start !important;
}
