@import '~@3tc/styleguide/src/resources/variables';
@import '~@3tc/styleguide';

* {
    outline: none !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', Calibri, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
}

#root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

.bp3-input {
    color: inherit;
}

.bp3-html-select select {
    color: $dark-petrol !important;
}

.DayPicker-Day {
    color: $dark-petrol !important;
}

.DayPicker-Day--outside {
    color: $mist !important;
}

a {
    text-decoration: underline;
    transition: color 200ms ease-in-out;

    &:hover {
        color: white;
    }
}

nav {
    button {
        padding-left: 3px !important;
        margin-bottom: -2px !important;
    }
    a {
        text-decoration: none !important;
        outline: none !important;
    }
    .wm-quickSearch-input {
        .bp3-icon {
            top: 3px;
            left: 2px !important;
        }
    }
}

// global print styles
@media print {
    .action-button {
        display: none;
    }
}