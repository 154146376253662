@import "../../scss/colours";

.bp3-spinner {
    .bp3-spinner-animation {
        .bp3-spinner-track {
            @include stroke("mist", 20);
        }
        .bp3-spinner-head {
            @include stroke("mist", 80);
        }
    }
}
