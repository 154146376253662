// core brand colours
$fuse: #e73930;
$petrol: #273c4e;
$spark: #ffcf21;
$mist: #a7a8aa;

// Corporate colours
$colour-names: (
    "fuse": $fuse, 
    "mist": $mist, 
    "petrol": $petrol, 
    "spark": $spark,
    "white": white,
    "black": black,
    "dark-petrol": scale-color($color: $petrol, $lightness: -43%)
);

// additional colours
$dark-petrol: darken($petrol, 10);
$light-petrol: lighten($petrol, 10);

$opacities: 25, 50, 60, 75, 80, 90;

@mixin make-colour($colour, $rgba) {
    .#{$colour} {
        color: $rgba !important;
    }
}

@mixin make-bg-colour($colour, $rgba) {
    .bg-#{$colour} {
        background-color: $rgba !important;
    }
}

@mixin make-colours($names, $opacities) {
    @each $colour, $rgb in $names {
    
        @include make-colour($colour, $rgb);

        @each $opacity in $opacities {
            $name: #{$colour}-#{$opacity};
            $alpha: calc($opacity / 100);
            @include make-colour($name, rgba($rgb, $alpha));
        }
    }
}

@mixin make-bg-colours($names, $opacities) {
    @each $colour, $rgb in $names {

        @include make-bg-colour($colour, $rgb);

        @each $opacity in $opacities {
            $name: #{$colour}-#{$opacity};
            $alpha: calc($opacity / 100);
            @include make-bg-colour($name, rgba($rgb, $alpha));
        }
    }
}

@include make-colours($colour-names, $opacities);
@include make-bg-colours($colour-names, $opacities);

@mixin colour($name, $opacity: 100) {
    $rgb: map-get($map: $colour-names, $key: $name);
    $alpha: calc($opacity / 100);
    color: rgba($rgb, $alpha) !important;
}

@mixin stroke($name, $opacity: 100) {
    $rgb: map-get($map: $colour-names, $key: $name);
    $alpha: calc($opacity / 100);
    stroke: rgba($rgb, $alpha) !important;
}

@mixin background-colour($name, $opacity: 100) {
    $rgb: map-get($map: $colour-names, $key: $name);
    $alpha: calc($opacity / 100);
    background-color: rgba($rgb, $alpha) !important;
}

@mixin border($border: solid, $width: 1px, $colour: black, $opacity: 100) {
    $rgb: map-get($map: $colour-names, $key: $colour);
    $alpha: calc($opacity / 100);
    border: $border $width rgba($rgb, $alpha) !important;
}

@mixin border-edge($edge, $border: solid, $width: 1px, $colour: black, $opacity: 100) {
    $rgb: map-get($map: $colour-names, $key: $colour);
    $alpha: calc($opacity / 100);
    border-#{$edge}: $border $width rgba($rgb, $alpha) !important;
}

@mixin box-shadow($colour) {
    $rgb: map-get($map: $colour-names, $key: $colour);
    box-shadow: 
        0 0 0 1px rgba($rgb,0.1),
        0 2px 4px rgba($rgb,0.2),
        0 8px 24px rgba($rgb,0.2) !important;
}
