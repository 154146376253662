@import './colours';

.wm-hidden {
    opacity: 0 !important;
    visibility: hidden !important;
}

.wm-tooltip.bp3-tooltip, .wm-tooltip.bp3-popover {
    .bp3-popover-content {
        background-color: rgba(#000000, 0.5);
        font-size: 0.7rem;
        padding: 0.25rem 0.5rem;
    }
}
