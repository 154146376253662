.wm-dialog-portal {
    .hidden_waterSource_inputReference_container {
        display: flex;
        max-width: 335px;
        max-height: 50px;
    
        input {
            margin-right: 1rem;
        }

        .action-button {
            width: 50px;
            margin: 0;
            text-align: center;
            .bp3-icon {
                margin-left: auto;
                margin-right: auto;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.dateInput {
    &.createInspection-dateInput {
        flex-grow: 1;
        max-width: unset;
        width: unset;
    }
}