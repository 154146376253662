@import '~@3tc/styleguide/src/resources/variables';
@import './shared';
@import './colours';

.dateInput {
    width: 100%;
    max-width: 335px;
    border-bottom: 2px solid transparent;

    .bp3-popover-target {
        width: 100%;
    }

    input {
        padding: 12px;
        min-height: 48px;
        border: 3px dashed transparent;

        &:focus {
            border-color: $spark;
        }
    }

    .DayPicker-Weekday {
        color: $dark-petrol;
    }
}

.input {
    min-height: 44px;
    height: auto !important;
    text-transform: uppercase;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.input[type="checkbox"] {
    width: auto;
    display: inline-block;
    min-height: 0;
}

.input[type="checkbox"]+.input__label--checkbox {
    display: inline-block;
    margin-left: 5px;
    margin-top: 0;
}

.input__label--checkbox+label {
    display: block;
}

select {
    cursor: pointer;
    @include colour("white");
}

.input__group {
    margin-bottom: 1rem;
    align-items: center;

    .input {
        margin: 0;
    }

    .input__label {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 8px;
        min-width: 128px;
        max-width: 128px;
    }

    .input__label+.input {
        margin-top: 0;
    }
}

.input__group_side-panel {
    margin-bottom: 0.5rem;
}

.formGroup {
    display: flex;
}

.bp3-input {
    background-color: $petrol  !important;
    border: 3px dashed transparent;

    &:focus {
        border-color: $spark;
        outline: none;
        box-shadow: none;
    }
}

.bp3-tabs {

    .bp3-tab-list {
        .bp3-tab {
            @include colour("white");
            margin-right: 0 !important;
            padding-left: 11px;
            padding-right: 11px;
            outline: none;

            &[aria-selected="true"] {
                @include colour("spark");
            }

            &-indicator {
                @include background-colour("spark");
            }

            &.disabled {
                @include colour("mist");
            }
        }
    }
}

.bp3-control.bp3-radio {
    input:checked~.bp3-control-indicator {
        @include background-colour("spark");
    }

    input:checked~.bp3-control-indicator::before {
        background-image: radial-gradient($petrol, $petrol, 28%, transparent 32%) !important;
    }
}

.bp3-switch {
    margin-bottom: 5px !important;

    input:checked~.bp3-control-indicator {
        background-color: #ffcf21 !important;

        &:before {
            background-color: #273c4e;
        }
    }

    .bp3-control-indicator {
        margin-right: 4px;
    }

    .switchLabel {
        margin-left: 6px !important;
        font-size: 14px;
        font-weight: bold;
    }
}

.wm-dialog-portal {
    .bp3-dialog {
        background-color: $dark-petrol;
        @include colour("white");
        border-radius: unset;

        .bp3-button {
            background: transparent;
            border-radius: 0;
            box-shadow: unset;
            margin: 0;

            @include colour("white");

            :hover {
                @include colour("spark");
            }

            :active {
                @include colour("fuse")
            }
        }

        .bp3-button.bp3-disabled,
        .bp3-button:disabled {
            @include colour("white", 25);

            :hover {
                @include colour("white", 25);
            }
        }

        .bp3-dialog-header {
            background: transparent;
            box-shadow: unset;
            margin-bottom: 0.5rem;
            padding-left: 1rem;

            .bp3-heading {
                @include colour("spark");
            }
        }

        .wm-dialog-body,
        .wm-dialog-buttonContainer {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .wm-dialog-body {
            .bp3-form-group {
                .bp3-label {
                    .bp3-text-muted {
                        @include colour("white", 25);
                    }
                }
            }

            .bp3-form-group.bp3-disabled {
                .bp3-label {
                    @include colour("white");
                }

                .bp3-input {
                    @include colour("white");
                    box-shadow: none;
                }
            }

            input {
                @include colour("white");
            }

            input::placeholder {
                @extend .mist-75;
            }
        }

        .input[type="radio"]:checked+label:after,
        .input[type="radio"]:not(:checked)+label:after {
            top: 6px;
            left: 7px;
        }

        .wm-dialog-buttonContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .wm-dialog-saveButton {
            @include border($width: 2px, $colour: "spark");
            @include colour("spark");
            font-weight: bold;

            &.bp3-disabled,
            &:disabled {
                border: none !important;
            }
        }
    }
}

.bp3-popover.bp3-select-popover {
    @include background-colour("petrol");
    @include colour("white");

    border-radius: 0 !important;
    @include box-shadow("spark");

    .bp3-popover-content {
        @include background-colour("petrol");
        @include colour("white");

        border-radius: 0 !important;
        padding: 0 !important;

        .bp3-menu {
            @include background-colour("petrol");
            @include colour("white");

            border-radius: 0 !important;

            .wm-select-item {
                border-radius: 0 !important;
            }

            .wm-select-item.bp3-active,
            .wm-select-item:active,
            .wm-select-item:hover {
                @include colour("spark");
                @include background-colour("white", 10);
            }
        }
    }
}

.input__label--checkbox {
    margin-right: 32px;
}

.invalid {
    border-bottom: 2px solid red !important;
}

// Date picker popover needs to be on top of everything
.bp3-transition-container {
    z-index: 1000;
}