@import '../../scss/colours';

.controlHeader {
    @include colour("spark");
    @include border-edge(bottom, solid, 1px, "spark");
    width: 100%;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;

    .controlHeaderMain {
        display: flex;
        justify-content: space-between;

        .controlHeader-primary {
            font-size: 1.5rem;
        }

        .controlHeader-secondary {
            font-size: 1rem;
            align-self: center;
            margin-bottom: 0;
        }

        .controlHeader-secondary-footer {
            font-size: 0.65rem;
            text-align: right;
        }
    }
    
    .secondaryHeaderContainer {
        display: flex;
        flex-direction: column;
    }

    input[type="text"] {
        @include colour("spark");
        padding: 3px;
    }

    select {
        @include colour("spark");
        padding: 3px;
        
        &#tertiaryField {
            margin-top: 3px;
        }
    }

    a {
        color: inherit;
    }
}