.value {
    align-self: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    &.normalCase {
        text-transform: unset;
    }
}
