@import "../../../scss/colours";

.root {
    .category {
        color: $spark
    }

    .operationalStatus {
        font-size: 1rem;
        align-self: center;
        margin-bottom: 0;

        .container {
            color: $spark;
        }

        .value {
            font-weight: normal;
        }

        .override {
            color: $spark;
            font-size: 0.65rem;
            text-align: right;
        }
    }
}