@import "../../../scss/colours";

.root {
    border-top: 1px solid $spark;
    border-bottom: 1px solid $spark;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px 0;
    padding: 16px 0;
    width: 100%;

    .container {
        display: flex;
        flex-direction: row;
    }
}
