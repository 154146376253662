@import "../../../../scss/colours";

.root {
    display: flex;
    flex-direction: row;

    .button {
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        height: fit-content;
        background-color: transparent;
        color: scale-color($color: white, $lightness: -10%);

        &:hover {
            color: white;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &.highlight {
        color: $spark;

        .button {
            color: $spark;
            text-decoration: underline;
        }
    }
}

.disabled {
    color: $mist;
}

.field {
    display: flex;
    flex-direction: row;

    .label {
        margin-right: 4px;
        color: $mist;

        &.tasks {
            white-space: nowrap;
        }
    }
    .value {
        color: white;
        font-weight: bold;

        &.tasks {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
