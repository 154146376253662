@import "../../../scss/colours";

.locationContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .eastingNorthing {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .gridRef {
        font-style: italic;
    }
}

.externalIdInfoText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
    color: $spark;
    span {
        margin-right: 10px;
    }

    &Error {
        color: red;
        font-weight: bold;
    }
}