.roadSpeed {
  padding: 2px 0px 0px 1px;
  font-size: 10px;
  font-weight: bold;
  color: #eeeeee;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #eeeeee;
  margin-right: 8px;
}