@import "../../../../scss/colours";

.defectControlContainer {
    width: 100%;

    .action-buttons-container {
        margin: 10px 0;
        button {
            margin-right: 5px;
        }
    }

    .defectControl {
        padding: 0 20px 20px 20px;
        @include colour("white");
    }

}