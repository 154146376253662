@import "../../../scss/colours";

$-medium: 500;
$-regular: normal;
$-light: light;

$-font-size-ratio: 0.0625;

@mixin _fontSize($pixels) {
    font-size: unquote("#{$pixels * $-font-size-ratio}rem");
}

@mixin _letterSpacing($pixels) { 
    letter-spacing: unquote("#{$pixels * $-font-size-ratio}rem");
}

.typography {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    &.h1 {
        @include _fontSize(97);
        font-weight: $-light;
        @include _letterSpacing(-1.5);
        text-transform: none;
    }
    &.h2 {
        @include _fontSize(61);
        font-weight: $-light;
        @include _letterSpacing(-0.5);
        text-transform: none;
    }
    &.h3 {
        @include _fontSize(48);
        font-weight: $-regular;
        @include _letterSpacing(0);
        text-transform: none;
    }
    &.h4 {
        @include _fontSize(34);
        font-weight: $-regular;
        @include _letterSpacing(0.2);
        text-transform: none;
    }
    &.h5 {
        @include _fontSize(24);
        font-weight: $-regular;
        @include _letterSpacing(0);
        text-transform: none;
    }
    &.h6 {
        @include _fontSize(20);
        font-weight: $-medium;
        @include _letterSpacing(0.15);
        text-transform: none;
    }
    &.subtitle1 {
        @include _fontSize(16);
        font-weight: $-regular;
        @include _letterSpacing(0.15);
        text-transform: none;
    }
    &.subtitle2 {
        @include _fontSize(14);
        font-weight: $-medium;
        @include _letterSpacing( 0.1);
        text-transform: none;
    }
    &.body1 {
        @include _fontSize(16);
        font-weight: $-regular;
        @include _letterSpacing(0.5);
        text-transform:  none;
    }
    &.body2 {
        @include _fontSize(14);
        font-weight: $-regular;
        @include _letterSpacing(0.25);
        text-transform: none;
    }
    &.button {
        @include _fontSize(14);
        font-weight: $-medium;
        @include _letterSpacing(1.25);
        text-transform: uppercase;
    }
    &.caption {
        @include _fontSize(12);
        font-weight: $-regular;
        @include _letterSpacing(0.4);
        text-transform:  none;
    }
    &.overline {
        @include _fontSize(10);
        font-weight: $-regular ;
        @include _letterSpacing(1.5);
        text-transform:  uppercase;
    }

    &.capitalize {
        text-transform: capitalize;
    }
    &.lowercase {
        text-transform: lowercase;
    }
    &.uppercase {
        text-transform: uppercase;
    }
}