@import "../../../../../scss/colours";
@import "../../../../../scss/responsiveTable";

.responsiveTable {
    .file {
        &:first-child {
            border-top: none;
            padding-top: 0;
        }

        .field {
            display: flex;
            flex-direction: column;

            .descriptionContainer {
                align-items: stretch;
                display: flex;
                flex-direction: row;
                margin-bottom: 0;

                &>div {
                    width: 100%;

                    &>input {
                        background-color: scale-color($color: $petrol, $lightness: 10%);
                        border: none;
                    }
                }
            }

            .invalid {
                color: $mist;
            }
        }
    
        .label {
            color: $mist;
        }
    }
}

@media screen and (min-width: 768px) {
    .responsiveTable {
        .file {
            .field {
                display: table-cell;
                &:first-child {
                    padding-left: 8px;
                }
                &:last-child {
                    padding-right: 8px;
                }
            }
            .label {
                display: none;
            }
        }
    }
}